import React, { useEffect } from 'react';

// import { useTranslation } from 'next-i18next';
// import PropTypes from 'prop-types';
import anime from 'animejs';
import styles from './IconCustomerService.module.scss';

const IconCustomerService = ({ shouldStart = false, id = '' }) => {
    const CustomerServiceAnimate = () => {
        anime({
            targets: `.${id}serviceLine`,
            translateY: 2,
            translateX: -2,
            easing: 'easeInOutSine',
            direction: 'alternate',
            duration: 300,
            loop: false,
        });
    };

    const ReverseCustomerServiceAnimate = () => {
        anime({
            targets: `.${id}serviceLine`,
            translateY: 0,
            translateX: 0,
            easing: 'easeInOutSine',
            direction: 'alternate',
            duration: 300,
            loop: false,
        });
    };

    useEffect(() => {
        shouldStart
            ? CustomerServiceAnimate()
            : ReverseCustomerServiceAnimate();
    });

    return (
        <div className={styles['IconCustomerService']}>
            <svg
                viewBox="0 0 50 51"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M16.905 25.83a19.573 19.573 0 0 0 9.13 9.107 1.872 1.872 0 0 0 1.85-.14l5.853-3.91a1.85 1.85 0 0 1 1.78-.164l10.956 4.706a1.849 1.849 0 0 1 1.124 1.943 11.238 11.238 0 0 1-11.144 9.81 31.84 31.84 0 0 1-31.84-31.84 11.238 11.238 0 0 1 9.809-11.145 1.85 1.85 0 0 1 1.943 1.124l4.706 10.98a1.873 1.873 0 0 1-.14 1.756l-3.91 5.947a1.873 1.873 0 0 0-.117 1.826v0z"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    className={id + 'serviceLine'}
                    d="M31.613 11.152v9.365h9.365m-9.365 0L40.725 11"
                    stroke="#feca01"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};

IconCustomerService.propTypes = {};

export default IconCustomerService;
